<template>
  <v-sheet color="grey lighten-5">
    <v-container fluid>
      <v-row
        align="start"
        justify="start"
        class="pa-1"
        v-if="item.productivity"
      >
        <v-col cols="12">
          <v-data-iterator hide-default-footer :items="item.productivity">
            <template v-slot:header>
              <v-row
                align="start"
                justify="start"
                class="pa-1"
                v-if="item.productivity"
              >
                <v-col cols="12">
                  <v-card class="grey lighten-4">
                    <v-row no-gutters>
                      <v-col cols="6" md="2" class="">
                        <div class="subtitle-1 pa-2">
                          Planned productivity
                        </div>
                        <div class="title">
                          {{ plannedProductivity }}
                        </div>
                      </v-col>
                      <v-divider vertical />
                      <v-col cols="6" md="2" class="">
                        <div class="subtitle-1 pa-2">
                          Productive hours
                        </div>
                        <div class="title">
                          {{ productiveHours }}
                        </div>
                      </v-col>
                      <v-col cols="6" md="2" class="">
                        <div class="subtitle-1 pa-2">
                          Unproductive Hours
                        </div>
                        <div class="title">
                          {{ unproductiveHours }}
                        </div>
                      </v-col>
                      <v-col cols="6" md="2" class="">
                        <div class="subtitle-1 pa-2">
                          Vacation hours
                        </div>
                        <div class="title">
                          {{ vacationHours }}
                        </div>
                      </v-col>
                      <v-col cols="6" md="2">
                        <div class="subtitle-1 pa-2">
                          Sickness hours
                        </div>
                        <div class="title">
                          {{ sicknessHours }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            >
            <template v-slot:no-data>
              <v-card class="pa-1" flat>
                <v-toolbar color="grey lighten-3" dense
                  ><v-toolbar-title>Info</v-toolbar-title></v-toolbar
                >
                <v-alert
                  border="bottom"
                  colored-border
                  color="primary"
                  elevation="2"
                  type="info"
                >
                  No productivity imported for this worker
                </v-alert>
              </v-card>
            </template>
            >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="(prod, index) in props.items"
                  :key="index"
                  class="pa-4"
                  cols="12"
                >
                  <v-card class="dekra-card">
                    <v-row no-gutters>
                      <v-col
                        cols="10"
                        v-if="prod.workforce_request"
                        class="title font-weight-bold text-left pa-2"
                      >
                        {{ prod.workforce_request.name
                        }}<span v-if="prod.workforce_request.company"
                          >@{{ prod.workforce_request.company.name }}</span
                        >
                      </v-col>
                      <v-col cols="2" class="title font-weight-bold pa-2">
                        Import date
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row v-if="prod" no-gutters>
                      <v-col cols="6" md="2">
                        <div class="subtitle-1 pa-2">
                          Planned productivity
                        </div>
                        <div class="title">
                          {{ prod.planned_productive_hours }}
                        </div>
                      </v-col>
                      <v-col cols="6" md="2">
                        <div class="subtitle-1 pa-2">
                          Productive hours
                        </div>
                        <div class="title">
                          {{ prod.productive_hours }}
                        </div>
                      </v-col>
                      <v-col cols="6" md="2">
                        <div class="subtitle-1 pa-2">
                          Unproductive Hours
                        </div>
                        <div class="title">
                          {{ prod.unproductive_hours }}
                        </div>
                      </v-col>
                      <v-col cols="6" md="2">
                        <div class="subtitle-1 pa-2">
                          Vacation hours
                        </div>
                        <div class="title">
                          {{ prod.uh_vacations }}
                        </div>
                      </v-col>
                      <v-col cols="6" md="2">
                        <div class="subtitle-1 pa-2">
                          Sickness hours
                        </div>
                        <div class="title">
                          {{ prod.uh_sickness }}
                        </div>
                      </v-col>
                      <v-col cols="6" md="2">
                        <div class="subtitle-1 pa-2">
                          {{
                            formatDate(prod.productivity_import.report_for_date)
                          }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import permission from "@/mixins/permission";
import date from "@/mixins/date";
import _ from "lodash";
export default {
  Name: "productivityTab",
  mixins: [permission, date],
  data() {
    return {};
  },
  props: {
    item: {
      required: true,
      type: [Object, Array]
    }
  },
  mounted() {},
  computed: {
    plannedProductivity() {
      if (this.item.productivity) {
        let valueReturn = _.sumBy(
          this.item.productivity,
          "productivity_import.planned_productive_hours"
        );
        return valueReturn;
      }
      return null;
    },
    productiveHours() {
      if (this.item.productivity) {
        let valueReturn = _.sumBy(
          this.item.productivity,
          "productivity_import.productive_hours"
        );
        return valueReturn;
      }
      return null;
    },
    unproductiveHours() {
      if (this.item.productivity) {
        let valueReturn = _.sumBy(
          this.item.productivity,
          "productivity_import.unproductive_hours"
        );
        return valueReturn;
      }
      return null;
    },
    vacationHours() {
      if (this.item.productivity) {
        let valueReturn = _.sumBy(
          this.item.productivity,
          "productivity_import.uh_vacations"
        );
        return valueReturn;
      }
      return null;
    },
    sicknessHours() {
      if (this.item.productivity) {
        let valueReturn = _.sumBy(
          this.item.productivity,
          "productivity_import.uh_sickness"
        );
        return valueReturn;
      }
      return null;
    }
  },
  methods: {}
};
</script>
<style scoped>
.dekra-card {
  border-bottom: 5px solid #017d40;
}
</style>
